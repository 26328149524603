<template>
  <PDialog 
    title="Melding" 
    classes="left-0 md:left-auto md:w-4/5" 
    :loading="isLoading"
    @close="$emit('close')"
  >
    <div class="flex-1 md:flex md:flex-row-reverse overflow-y-auto md:overflow-y-hidden shadow-inner bg-gray-100">
    
      <div class="md:w-80 border-l border-gray-300 p-4 overflow-y-scroll flex flex-col space-y-4">

        <PSelect 
          :label="$tk('Common.General.Type')" 
          v-model="message.type" 
          :items="types" 
        />

        <PDatePicker 
          :allOpen="true"
          :clearable="true"
          :label="$tk('Common.General.Created')" 
          v-model="message.created" 
        />

        <PDatePicker 
          :allOpen="true"
          :clearable="true"
          :label="$tk('MessageDialog.ShowFrom')" 
          v-model="message.displayFrom" 
        />

        <PDatePicker 
          :allOpen="true"
          :clearable="true"
          :label="$tk('MessageDialog.ShowTo')" 
          v-model="message.displayTo" 
        />
        
        <PSelect 
          :label="$tk('Common.General.Country')" 
          v-model="message.company" 
          itemValue="id"
          itemText="name"
          :items="companies" 
          :clearable="true"
        />

        <PSelect 
          :label="$tk('Common.General.Language')" 
          v-model="message.language" 
          :items="languages" 
        />

        <PCheckbox 
          :label="$tk('MessageDialog.MustRead')" 
          v-model="message.mustRead" 
        />

        <PSelect 
          :label="$tk('Common.General.UserLevel')" 
          v-model="message.userLevel" 
          :items="userLevels" 
          :clearable="true"
        />
        
        <PSelect 
          :label="$tk('Common.General.LocationType')"
          v-model="message.locationType" 
          :items="locationTypes" 
          :clearable="true"
        />

      </div>

      <div class="flex-1 flex flex-col overflow-y-hidden">
          <div class="p-4 border-b border-gray-300">
            <PInput v-model="message.title" placeholder="Tittel" />          
          </div>          
          <div class="flex-1 flex overflow-y-hidden">
            
            <PRichTextEditor 
              class="focus:outline-none" 
              v-model="message.text"
            >
            </PRichTextEditor>         
          </div>
      </div>      

    </div>
    
    <PDialogActions>
      <div class="flex justify-between items-center w-full">
        <div>

          <PButton 
            color="secondary" 
            icon="trash-alt"
            @click="onDelete"
            v-if="canDelete"            
          >
            <span v-html="$tk('Common.Actions.Delete')"></span>
          </PButton>

        </div>
        <div>

          <PButton 
            @click="$emit('close')" 
            color="secondary"            
          >
            <span v-html="$tk('Common.Actions.Close')"></span>
          </PButton>

          <PButton 
            class="ml-2" 
            @click="onSubmit"            
          >
            <span v-html="$tk('Common.Actions.Save')"></span>
          </PButton>

        </div>
      </div>
    </PDialogActions>

  </PDialog>
</template>

<script>

import http from "@/http"

import PRichTextEditor from "@/components/forms/PRichTextEditor.vue"

export default {
  
  components: {
    PRichTextEditor
  },

  props: {
    id: {
      type: String,
      required: true
    },
    selectedType: {
      type: String,
      default: "Message"
    }
  },

  data () {
    return {
      message: {
        id: "",
        title: "",
        text: "",
        created: "",
        displayFrom: "",
        displayTo: "",
        language: "",
        mustRead: false,
        userLevel: "",
        locationType: "",
        priority: "",
        path: "",
        type: "",
        company: ""        
      },      
      types: [
        { value: "Message", text: this.$tk("Common.MessageType.Messages") },
        { value: "Article", text: this.$tk("Common.MessageType.Articles") },
        { value: "Guide", text: this.$tk("Common.MessageType.Guides") },
        { value: "Report", text: this.$tk("Common.MessageType.Reports") },
        { value: "Login", text: this.$tk("Common.MessageType.Login") },
        { value: "System", text: this.$tk("Common.MessageType.System") }
      ],
      languages: [
        { value: "", text: `(${this.$tk("Common.General.None")})` },
        { value: "nb-NO", text: this.$tk("Common.Language.Norwegian") },
        { value: "sv-SE", text: this.$tk("Common.Language.Swedish") },
        { value: "da-DK", text: this.$tk("Common.Language.Danish") },
        { value: "fi-FI", text: this.$tk("Common.Language.Finnish") },
        { value: "en-US", text: this.$tk("Common.Language.English") }
      ],
      userLevels: [
        { value: "User", text: this.$tk("Common.UserLevel.LoggedInUser") },
        { value: "Location", text: this.$tk("Common.UserLevel.AdminLocation") },
        { value: "Customer", text: this.$tk("Common.UserLevel.AdminCustomer") },
        { value: "Global", text: this.$tk("Common.UserLevel.AdminGlobal") },
        { value: "None", text: this.$tk("Common.UserLevel.Anonymous") },
      ],
      locationTypes: [
        { value: "", text: this.$tk("Common.LocationType.All") },
        { value: "CustVend", text: this.$tk("Common.LocationType.CustomerVendor") },
        // { value: "Retail", text: this.$tk("Common.LocationType.Retail") },
      ],
      companies: [],
      isLoading: false
    }
  },

  computed: {
    
    canDelete () {
      return this.message.id !== ""
    }

  },

  methods: {
    
    async onDelete () {

      try {

        await http.delete("message?id=" + this.message.id)
        this.$emit("close")

      } catch (error) {
        
        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })

      }

    },

    async onSubmit () {

      try {

        await http.post("message", this.message)
        this.$emit("close")

      } catch (error) {

        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })

      }

    }
  },

  async created () {  

    try {
      this.companies = await http.get("Companies")
    } catch {
      this.companies = []
    }

    if (this.id) {
      
      this.isLoading = true    

      try {
        
        this.message = await http.get("Message", { params: { id: this.id } })

      } catch (error) {

        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })

      }

      this.isLoading = false

    } else {

      this.message.type = this.selectedType

    }

  }
}
</script>
