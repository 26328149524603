<template>
  <div class="flex-1 flex flex-col overflow-y-hidden">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="rte-buttons flex border-b border-gray-300">

        <button :class="{ 'is-active': isActive.bold() }" @click="commands.bold">          
          <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M17.194 10.962A6.271 6.271 0 0012.844.248H4.3a1.25 1.25 0 000 2.5h1.013a.25.25 0 01.25.25V21a.25.25 0 01-.25.25H4.3a1.25 1.25 0 100 2.5h9.963a6.742 6.742 0 002.93-12.786zm-4.35-8.214a3.762 3.762 0 010 7.523H8.313a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25zm1.42 18.5H8.313a.25.25 0 01-.25-.25v-7.977a.25.25 0 01.25-.25H14.264a4.239 4.239 0 010 8.477z"/></svg>
        </button>

        <button class="border-r border-gray-300" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
          <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M22.5.248h-7.637a1.25 1.25 0 000 2.5h1.086a.25.25 0 01.211.384L4.78 21.017a.5.5 0 01-.422.231H1.5a1.25 1.25 0 000 2.5h7.637a1.25 1.25 0 000-2.5H8.051a.25.25 0 01-.211-.384L19.22 2.98a.5.5 0 01.422-.232H22.5a1.25 1.25 0 000-2.5z"/></svg>
        </button>

        <button @click="commands.undo">
          <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M17.786 3.77a12.542 12.542 0 00-12.965-.865.249.249 0 01-.292-.045L1.937.269A.507.507 0 001.392.16a.5.5 0 00-.308.462v6.7a.5.5 0 00.5.5h6.7a.5.5 0 00.354-.854L6.783 5.115a.253.253 0 01-.068-.228.249.249 0 01.152-.181 10 10 0 019.466 1.1 9.759 9.759 0 01.094 15.809 1.25 1.25 0 001.473 2.016 12.122 12.122 0 005.013-9.961 12.125 12.125 0 00-5.127-9.9z"/></svg>
        </button>

        <button class="border-r border-gray-300" @click="commands.redo">
          <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M22.608.161a.5.5 0 00-.545.108L19.472 2.86a.25.25 0 01-.292.045 12.537 12.537 0 00-12.966.865A12.259 12.259 0 006.1 23.632a1.25 1.25 0 001.476-2.018 9.759 9.759 0 01.091-15.809 10 10 0 019.466-1.1.25.25 0 01.084.409l-1.85 1.85a.5.5 0 00.354.853h6.7a.5.5 0 00.5-.5V.623a.5.5 0 00-.313-.462z"/></svg>
        </button>

        <button :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
          <svg class="h-4 w-4" viewBox="0 0 24 24"><circle cx="2.5" cy="3.998" r="2.5"/><path d="M8.5 5H23a1 1 0 000-2H8.5a1 1 0 000 2z"/><circle cx="2.5" cy="11.998" r="2.5"/><path d="M23 11H8.5a1 1 0 000 2H23a1 1 0 000-2z"/><circle cx="2.5" cy="19.998" r="2.5"/><path d="M23 19H8.5a1 1 0 000 2H23a1 1 0 000-2z"/></svg>
        </button>

        <button  :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
          <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M7.75 4.5h15a1 1 0 000-2h-15a1 1 0 000 2zM22.75 11h-15a1 1 0 100 2h15a1 1 0 000-2zM22.75 19.5h-15a1 1 0 000 2h15a1 1 0 000-2zM2.212 17.248a2 2 0 00-1.933 1.484.75.75 0 101.45.386.5.5 0 11.483.63.75.75 0 100 1.5.5.5 0 11-.482.635.75.75 0 10-1.445.4 2 2 0 103.589-1.648.251.251 0 010-.278 2 2 0 00-1.662-3.111zM4.25 10.748a2 2 0 00-4 0 .75.75 0 001.5 0 .5.5 0 011 0 1.031 1.031 0 01-.227.645L.414 14.029A.75.75 0 001 15.248h2.5a.75.75 0 000-1.5h-.419a.249.249 0 01-.195-.406L3.7 12.33a2.544 2.544 0 00.55-1.582zM4 5.248h-.25A.25.25 0 013.5 5V1.623A1.377 1.377 0 002.125.248H1.5a.75.75 0 000 1.5h.25A.25.25 0 012 2v3a.25.25 0 01-.25.25H1.5a.75.75 0 000 1.5H4a.75.75 0 000-1.5z"/></svg>
        </button>

        <button @click="showImagePrompt(commands.image)" >
          <svg class="h-4 w-4" viewBox="0 0 24 24"><circle cx="9.75" cy="6.247" r="2.25"/><path d="M16.916 8.71A1.027 1.027 0 0016 8.158a1.007 1.007 0 00-.892.586l-1.558 3.434a.249.249 0 01-.422.053l-.82-1.024a1 1 0 00-.813-.376 1.007 1.007 0 00-.787.426L7.59 15.71a.5.5 0 00.41.79h12a.5.5 0 00.425-.237.5.5 0 00.022-.486z"/><path d="M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z"/><path d="M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z"/></svg>
        </button>

        <button @click="commands.createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false })">
          <svg class="h-4 w-4" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M17 17v5h2a3 3 0 003-3v-2h-5zm-2 0H9v5h6v-5zm2-2h5V9h-5v6zm-2 0V9H9v6h6zm2-8h5V5a3 3 0 00-3-3h-2v5zm-2 0V2H9v5h6zm9 9.177V19a5 5 0 01-5 5H5a5 5 0 01-5-5V5a5 5 0 015-5h14a5 5 0 015 5v2.823a.843.843 0 010 .354v7.646a.843.843 0 010 .354zM7 2H5a3 3 0 00-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 003 3h2v-5H2z"/></svg>
        </button>

        <span class="flex bg-gray-300" v-if="isActive.table()">

          <button @click="commands.deleteTable" title="Slett tabell">
            <svg class="h-4 w-4"  viewBox="0 0 24 24"><path d="M19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875zM12.29 17H9v5h3.674c.356.75.841 1.426 1.427 2H5a5 5 0 01-5-5V5a5 5 0 015-5h14a5 5 0 015 5v2.823a.843.843 0 010 .354V14.1a7.018 7.018 0 00-2-1.427V9h-5v3.29a6.972 6.972 0 00-2 .965V9H9v6h4.255a6.972 6.972 0 00-.965 2zM17 7h5V5a3 3 0 00-3-3h-2v5zm-2 0V2H9v5h6zM7 2H5a3 3 0 00-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 003 3h2v-5H2z"/></svg>
          </button>
          <button @click="commands.addColumnBefore" title="Ny kolonne før">
            <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M19 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM2 19a3 3 0 006 0V5a3 3 0 10-6 0v14zm-2 0V5a5 5 0 1110 0v14a5 5 0 01-10 0z"/></svg>
          </button>
          <button @click="commands.addColumnAfter" title="Ny kolonne etter">
            <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M5 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875H6.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H2.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313H7.5zM16 19a3 3 0 006 0V5a3 3 0 00-6 0v14zm-2 0V5a5 5 0 0110 0v14a5 5 0 01-10 0z"/></svg>
          </button>
          <button @click="commands.deleteColumn" title="Slett kolonne">
            <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M12.641 21.931a7.01 7.01 0 001.146 1.74A5 5 0 017 19V5a5 5 0 1110 0v7.29a6.972 6.972 0 00-2 .965V5a3 3 0 00-6 0v14a3 3 0 003.641 2.931zM19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875z"/></svg>
          </button>
          <button @click="commands.addRowBefore" title="Ny rad før">
            <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M19 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM5 2a3 3 0 100 6h14a3 3 0 000-6H5zm0-2h14a5 5 0 010 10H5A5 5 0 115 0z"/></svg>
          </button>
          <button @click="commands.addRowAfter" title="Ny rad etter">
            <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M19 0a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V2.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312V7.5a.937.937 0 101.875 0V6.25c0-.173.14-.313.312-.313h1.25zM5 16a3 3 0 000 6h14a3 3 0 000-6H5zm0-2h14a5 5 0 010 10H5a5 5 0 010-10z"/></svg>
          </button>
          <button @click="commands.deleteRow" title="Slett rad">
            <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M13.255 15a6.972 6.972 0 00-.965 2H5A5 5 0 015 7h14a5 5 0 014.671 6.787 7.01 7.01 0 00-1.74-1.146A3 3 0 0019 9H5a3 3 0 000 6h8.255zM19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875z"/></svg>
          </button>
          <button @click="commands.toggleCellMerge" title="">
            <svg class="h-4 w-4" viewBox="0 0 24 24"><path d="M2 19a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H5a3 3 0 00-3 3v14zm-2 0V5a5 5 0 015-5h14a5 5 0 015 5v14a5 5 0 01-5 5H5a5 5 0 01-5-5zm12-9a1 1 0 011 1v2a1 1 0 01-2 0v-2a1 1 0 011-1zm0 6a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm0-13a1 1 0 011 1v3a1 1 0 01-2 0V4a1 1 0 011-1z"/></svg>
          </button>

        </span>
        
<!-- 
        <button
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          Strike
        </button>

        <button
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          Underline
        </button>

        <button
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          Code
        </button>

        <button
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          Paragraph
        </button>

        <button
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        

        <button
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          Quote
        </button>

        <button
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          Code
        </button>

        <button
          class="menubar__button"
          @click="commands.horizontal_rule"
        >
          HR
        </button> -->

        

      </div>
    </editor-menu-bar>

    <div class="flex-1 flex overflow-y-scroll">
      
      <editor-content class="flex-1 bg-white text-sm p-4 rte" :editor="editor" />
    </div>

  </div>
</template>

<script>
// import Icon from 'Components/Icon'
import { 
  Editor, 
  EditorContent, 
  EditorMenuBar 
} from "tiptap"

import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from "tiptap-extensions"

export default {

  components: {
    EditorContent,
    EditorMenuBar,
    // Icon,
  },
  
  props: {
    value: {
      type: String,
      default: ""
    }
  },

  data() {
    return {      
      editor: new Editor({        
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new Image(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: this.value,
        onUpdate: ( { getHTML } ) => { // state, getHTML, getJSON, transaction
          this.$emit("input", getHTML())
        }
      }),
    }
  }, 
  
  methods: {
    showImagePrompt(command) {
      const src = prompt("Skriv inn URL til bilde her")
      if (src !== null) {
        command({ src })
      }
    },
  },

  mounted () {
    this.$nextTick( () => {
      this.editor.view.dom.focus()
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },

}
</script>

<style>

.ProseMirror:focus {
    outline: none;
}

.rte-buttons button:focus {
  outline: none;
}

.rte-buttons button {
  height: theme("height.8");
  width: theme("width.8");
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: theme("fontSize.sm"); */
}

.rte-buttons button:hover {
    background-color: theme("colors.green.100");
}
.rte-buttons button.is-active {
    background-color: theme("colors.green.200");
}

.rte td, .rte th {
  min-width: 1em;
  border: 1px solid theme("colors.gray.300");
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}

.rte td > *,
.rte th > * {
  margin-bottom: 0;
}

.selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0; right: 0; top: 0; bottom: 0;
    background: rgba(206, 255, 200, 0.4);
    pointer-events: none;
  }
  
.column-resize-handle {
    position: absolute;
    right: -2px; top: 0; bottom: 0;
    width: 4px;
    z-index: 20;
    background-color: #adf;
    pointer-events: none;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

</style>