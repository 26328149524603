<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
    <div class="flex-1 overflow-y-scroll relative">

      <p-loader v-if="isLoading" />
      <table
        v-else
        class="data-table"
      >
        <thead>
          <tr>
            <th
              class="text-left"
              v-html="$tk('GlobalPublishing.Title')"
            ></th>
            <th
              class="text-center w-40"
              v-html="$tk('GlobalPublishing.Date')"
            ></th>
            <th
              class="text-left"
              v-html="$tk('Common.General.Country')"
            ></th>
            <th
              class="text-left"
              v-html="$tk('GlobalPublishing.Language')"
            ></th>
            <th
              class="w-24"
              v-html="$tk('GlobalPublishing.MustBeRead')"
            ></th>
            <th
              class="text-left"
              v-html="$tk('GlobalPublishing.LocationType')"
            ></th>
            <th
              class="text-center w-40"
              v-html="$tk('Common.General.From')"
            ></th>
            <th
              class="text-center w-40"
              v-html="$tk('Common.General.To')"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="parentId">
            <td colspan="11">
              <button
                class="flex items-center focus:outline-none"
                @click.prevent="parentId = lastParentId"
              >
                <font-awesome-icon :icon="['far', 'arrow-left']" />
                <div
                  class="ml-2"
                  v-html="$tk('Common.General.Back')"
                ></div>
              </button>
            </td>
          </tr>
          <tr
            v-for="(message, index) in filteredMessages"
            :key="index"
            :class="{ 'odd': index % 2 !== 0 }"
            @click="onSelect(message.id)"
          >
            <td class="font-bold">
              <div class="flex items-center">
                <div class="w-4">
                  <button
                    class="focus:outline-none"
                    v-if="hasChildren(message.id)"
                    @click.prevent.stop="onExpand(message.id)"
                  >
                    <font-awesome-icon :icon="['far', 'plus-square']" />
                  </button>
                </div>
                <div>{{ message.title }}</div>
              </div>
            </td>
            <td class="text-center">
              {{ message.created | dateAndTime }}
            </td>
            <td>
              {{ message.company }}
            </td>
            <td v-html="message.language === 'nb-NO' ? $tk('Common.Language.Norwegian') :
              message.language === 'sv-SE' ? $tk('Common.Language.Swedish') :
                message.language === 'da-DK' ? $tk('Common.Language.Danish') :
                  message.language === 'en-US' ? $tk('Common.Language.English') :
                    message.language === 'fi-FI' ? $tk('Common.Language.Finnish') : ''
              ">
            </td>
            <td class="text-center">
              <FontAwesomeIcon
                class="text-green-500"
                v-if="message.mustRead"
                :icon="['far', 'check']"
              />
            </td>
            <td>
              {{ message.locationType }}
            </td>
            <td class="text-center">
              {{ message.displayFrom | dateAndTime }}
            </td>
            <td class="text-center">
              {{ message.displayTo | dateAndTime }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="filters">
      <PSelect
        :items="types"
        v-model="selectedType"
      />
    </portal>

    <portal to="actions">
      <div class="ml-8 flex space-x-2">
        <PButton
          color="primary"
          @click="onCreate"
        >
          <span v-html="$tk('GlobalPublishing.CreateNew')"></span>
        </PButton>
      </div>
    </portal>

    <MessageDialog
      v-if="messageDialogVisible"
      :id="selectedMessageId"
      :selectedType="selectedType"
      @close="onClose"
    />

  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { filter, some } from "lodash"
import MessageDialog from "../dialogs/MessageDialog.vue"

export default {

  components: {
    MessageDialog
  },

  data() {
    return {
      messages: [],
      lastParentId: null,
      parentId: null,
      isLoading: false,
      selectedMessageId: "",
      types: [
        { value: "Message", text: this.$tk("Common.MessageType.Messages") },
        { value: "Article", text: this.$tk("Common.MessageType.Articles") },
        { value: "Guide", text: this.$tk("Common.MessageType.Guides") },
        // { value: "Report", text: this.$tk("Common.MessageType.Reports") },
        { value: "Login", text: this.$tk("Common.MessageType.Login") },
        { value: "System", text: this.$tk("Common.MessageType.System") }
      ],
      selectedType: "Message",
      messageDialogVisible: false
    }
  },

  computed: {
    ...mapGetters(["customer", "location"]),

    filteredMessages() {
      return filter(this.messages, message => message.parentId === this.parentId)
    }
  },

  methods: {

    hasChildren(id) {
      return some(this.messages, message => message.parentId === id)
    },

    onExpand(id) {
      this.lastParentId = this.parentId
      this.parentId = id
    },

    onCreate() {
      this.messageDialogVisible = true
    },

    onSelect(messageId) {
      this.selectedMessageId = messageId
      this.messageDialogVisible = true
    },

    async onClose() {
      this.messageDialogVisible = false
      this.selectedMessageId = ""
      await this.getMessages()
    },

    async getMessages() {
      this.isLoading = true
      this.messages = await http.get("Messages",
        {
          params: {
            language: "*",
            company: "*",
            all: true,
            type: this.selectedType
          }
        })
      this.isLoading = false
    }
  },

  watch: {
    selectedMessageId: {
      handler: async function(val) {
        if (!val) {
          await this.getMessages()
        }
      }, immediate: true
    },
    selectedType: function() {
      this.getMessages()
    }
  }
}
</script>

<style scoped>
th {
  @apply p-4 leading-4 bg-gradient-to-b from-gray-500 to-gray-600;
}

tr {
  background-color: theme('colors.gray.50');
}

tr.odd {
  background-color: theme('colors.gray.100')
}

tr:hover {
  background-color: theme('colors.orange.200');
}

td {
  @apply px-4 py-2;
  border-bottom: 1px solid theme('colors.gray.300');
  border-right: 1px solid theme('colors.gray.300');
}

tbody tr {
  cursor: pointer;
}
</style>